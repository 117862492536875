import React from "react"
import styled from "styled-components"

import Input from "./input"
import Radio from "./radio"
import Submit from "../base/submit"

const Form = styled.form`
  margin-bottom: 8.75em;
`

const MainForm = props => {
  return (
    <Form method="post" action="https://prod-09.centralus.logic.azure.com:443/workflows/f200d5e1b64e4e23b084cc1ad6c90e86/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=AZZI9HbMSRi2X1W5Bd0GYXv42dernAtwG-Dz6MvXRF8">
      <fieldset style={{
        borderColor: `hsl(26, 34%, 25%)`,
        border: `0px`,
      }}>
        <legend style={{
            color: `hsl(26, 34%, 25%)`,
        }}>
        </legend>
          <div style={{
          }}>

            <Input name="What is the name of your company or project?" type="text" />
            <br />
            <Input name="What is your name?" type="text" />
            <br />
            <Input name="What is your email address?" type="email" required />
            <br />
            <Input name="What is your phone number?" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
            <br />
            <Radio name="What can we do for you?" values={[
              'Create an amazing new product',
              'Improve my already awesome product',
              'Something else'
            ]} required />
            <br />
            <Input name="Can you tell us more about it?" type="textbox" />
            <br />
            {
            // <Radio name="What is your budget?"
            //        values={[
            //          'Less than $25,000',
            //          '$25,000 to $50,000',
            //          '$50,000 to $100,000',
            //          'More than $100,000',
            //          'To be determined'
            //        ]} />
            // <br />
            }
            <Input name="How did you hear about us?" type="text" />
            <br />
            <Submit />
          </div>
      </fieldset>
    </Form>
  )
}

export default MainForm
