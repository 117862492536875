import React from "react"
import styled from "styled-components"

import FormLabel from "./label"

const FormLabelContainer = styled.div`
  padding: 1.5em 0 0 0;
`

const FormInput = styled.input`
  color: hsl(26, 34%, 25%);
  width: 100%;
  padding: 0.5em;
  margin: 1rem 0;
  resize: vertical;
  background: transparent;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  border-bottom: 0.1em solid hsl(26, 34%, 25%);
  outline: none;
  :focus {
    border-bottom: 0.1em solid #ff4f00;
    transition: 1s;
  }
  @media (min-width: 600px) {
    width: 30em;
  }
`

const Input = props => {
  return (
    <>
      <FormLabelContainer>
        <FormLabel name={ props.name } />
        <br />
        <FormInput
          type={ props.type }
          name={ props.name.toLowerCase() }
          required={ props.required || false }
        />
      </FormLabelContainer>
    </>
  )
}

export default Input
