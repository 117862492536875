import React from "react"
import styled from "styled-components"

import FormLabel from "./label"

const FormLabelContainer = styled.div`
  padding: 1em 1em 1em 0;
`

const FormInputLabelContainer = styled.div`
`

const FormInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0.5rem 0.5rem;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid #999;
  color: hsl(26, 34%, 25%);
  height: 16px;
  width: 16px;
  outline: none;
  padding: 0.5em;
  resize: vertical;
  transition: 0.2s all linear;
  vertical-align: middle;
  :checked {
    background-color: #ff4f00;
    border: 2px solid #ff4f00;
  }
`

const Input = props => {
    const formInputs = props.values.map( (val, index) =>
      <>
        <br />
        <FormInput
          key={`form-radio-${val}-${index}`}
          id={`form-radio-${val}-${index}`}
          name={props.name}
          type='radio' 
          value={val}
        /> 
          <FormLabel htmlFor={`form-radio-${val}-${index}`} name={val} />
      </>
    );

  return (
    <>
      <FormLabelContainer>
        <FormLabel name={ props.name } />
      </FormLabelContainer>
      <FormInputLabelContainer>
        {formInputs}
      </FormInputLabelContainer>
    </>
  )
}

export default Input
