import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/get-started/main"

const GetStartedPage = () => (
  <Layout>
    <SEO title="Get Started" />
    <h1>What does success look like for your project?</h1>
    <Form />
  </Layout>
)

export default GetStartedPage
