import React from "react"
import styled from "styled-components"

const Button = styled.button`
  display:inline-block;
  border: 0.1em solid #ff4f00;
  border-radius: 2em;
  margin:0 0.3em 0.3em 0;
  padding: 0.75em 1.25em;
  color: #ff4f00;
  background-color: #f8f8ff;
  text-decoration: none;
  text-align:center;
  letter-spacing: 0.04em;
  transition: all 0.2s;
  :hover {
    background-color: #ff4f00;
    color: white;
  }
  @media all and (max-width:30em){
    display:block;
    margin:0.4em auto;
    float: right;
   }
`

const Element = () => (
    <Button type="submit">Submit</Button>
)

export default Element
