import React from "react"
import styled from "styled-components"

const FormLabel = styled.label`
  color: hsl(26, 34%, 25%);
  display: inline-block;
  font-size: 1.2em;
  vertical-align: middle;
`

const Element = ({ name }) => (
  <FormLabel>{name}</FormLabel>
)

export default Element;
